.filtersContainer {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 1rem;
  column-gap: 0.8rem;
  margin-inline: auto;
  margin-bottom: 0.2rem;

  .filterItem {
    grid-column: span 1 / span 1;
  }
}

@media only screen and (min-width: 700px) {
  .filtersContainer {
    width: 90%;
  }
}

@media only screen and (min-width: 1000px) {
  .filtersContainer {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .filtersContainer {
    width: 70%;
  }
}
