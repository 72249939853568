.actionButton {
  flex-grow: 1;
  text-align: center;
  color: black;
  padding: 0.4rem 0 0.2rem 0;
  border-right: 1px solid rgb(179, 178, 178);
  border-top: 1.2px solid rgb(189, 187, 187);
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.lastActionButton {
  border-right: 0;
}
