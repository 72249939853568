@use 'styles/variables' as variables;

html {
  font-size: 16px;

  * {
    margin: 0;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100vw;
    min-height: 100vh;
    background: linear-gradient(variables.$navbar-bg, white, white, transparent) !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    overflow-x: hidden;
  }
  .sort-filters-container {
    display: flex;
    justify-content: flex-end; 
    align-items: center;     
  
    & > * {
      margin-left: 1rem;
    }
  }
  

  hr {
    margin: 0;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 600;
  }

  h3 {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.33;
  }

  p,
  span {
    font-weight: 400;
  }

  a {
    text-decoration: none;
    text-transform: none;

    &:hover {
      text-transform: none;
      text-decoration: none;
    }
  }

  .noscrollbar {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
      display: none;
    }

    &::-webkit-scrollbar-button {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-scrollbar-track {
      width: 0 !important;
      height: 0 !important;
      background: transparent;
    }

    ::-webkit-scrollbar-track-piece {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }

    ::-webkit-scrollbar-corner {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-resizer {
      width: 0 !important;
      height: 0 !important;
    }
  }
}
