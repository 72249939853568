@use 'styles/variables' as variables;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  position: sticky;
  top: 0;
  inset: 0;
  padding: 0.2rem 0.5rem 0.4rem 1rem;

  .logoutContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.desktopContainer {
  display: grid;
  flex-direction: column;
  z-index: 100;
  row-gap: 0.1rem;
  padding-block: 0.5rem;
  background: transparent;
  padding: 0 1rem 0 1rem;
  position: sticky;
  top: 0;
  inset: 0;
  margin-inline: -1rem;

  .logo {
    height: 1.3rem;
  }
}
