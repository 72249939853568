@use 'styles/variables' as variables;

.searchInput {
  padding: 0.5rem 0 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 8px;
  transition: border 0.4s ease-in-out;
}

.searchInput::placeholder {
  color: variables.$secondary-color;
}

.searchInput:focus {
  border: solid 1px;
  border-color: variables.$secondary-color;
  outline: none;
}
