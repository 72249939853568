.row-created {
  background-color: #fff3cd; /* Light yellow */

  &:hover {
    background-color: #f0e2a3 !important; /* Darker yellow */
  }
}

.row-started {
  background-color: #f4e4f5; /* Light pink */

  &:hover {
    background-color: #e3c5e2 !important; /* Darker pink */
  }
}

.row-applied {
  background-color: #d1ecf1; /* Light blue */

  &:hover {
    background-color: #a6c8d7 !important; /* Darker blue */
  }
}

.row-completed {
  background-color: #c3e6cb; /* Soft green */

  &:hover {
    background-color: #a0c4a2 !important; /* Darker green */
  }
}

.row-default {
  background-color: #ffffff; /* Default white */

  &:hover {
    background-color: #eaeaea !important; /* Darker white/grey */
  }
}

.row-sent-to-finance-for-payment {
  background-color: #d7d7f7; /* Light violet */
  &:hover {
    background-color: #b5b5e6 !important; /* Darker white/grey */
  }
}
.row-payment-done {
  background-color: #f5cbc1; /* Soft orange */
  &:hover {
    background-color: #e6a99a !important; /* Darker white/grey */
  }
}
