.image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 4rem;
  width: 4rem;
}

.sectionContainer {
  overflow: hidden;
}

.sectionExpand {
  animation: expand 0.3s linear;
}

.sectionCollapse {
  animation: collapse 0.3s linear;
  height: 1rem;
}

@keyframes expand {
  0% {
    height: 1rem;
  }
  25% {
    height: 3rem;
  }
  50% {
    height: 6rem;
  }
  75% {
    height: 9rem;
  }
  85% {
    height: 12rem;
  }
  100% {
    height: auto;
  }
}
@keyframes collapse {
  0% {
    height: auto;
  }
  15% {
    height: 9rem;
  }
  25% {
    height: 6rem;
  }
  50% {
    height: 3rem;
  }
  75% {
    height: 1rem;
  }
  100% {
    height: 1rem;
  }
}
