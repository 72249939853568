@use 'styles/variables' as variables;

.card {
  display: flex;
  flex-direction: row;
  align-items: start;
  column-gap: 0.5rem;
  border-radius: 8px;
  background-color: white;
  padding: 0.3rem 0.5rem;
  box-shadow: 1px 1px 5px rgba(255, 77, 0, 0.3);
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    scale: 1.015;
  }
}

.labelandAmount {
  display: flex;
  flex-direction: column;

  .cardTitle {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1.1px;
    color: variables.$secondary-color;
  }

  .cardAmount {
    font-weight: 400;
    color: variables.$secondary-color;
  }
}

.cardLeadsContainer {
  padding: 0 4px;
  display: flex;
  background-color: variables.$secondary-color;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  color: white;
}

// .card {
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0 0 6px rgba(255, 77, 0, 0.3);
//   border-radius: 8px;
//   height: 3.5rem;
//   cursor: pointer;
//   background-color: white;
//   // border-top: solid 0.2px rgb(255, 77, 0);
//   // border-left: solid 0.2px rgb(255, 77, 0);
//   transition: all 0.2s ease-in;
//   position: relative;

//   &:hover {
//     box-shadow: 0 0 8px rgba(255, 77, 0, 0.3);
//     scale: 1.015;
//   }
// }

// .cardTitleContainer {
//   padding-top: 5px;
//   letter-spacing: 0.5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .cardLeadsContainer {
//   color: black;
//   right: -10px;
//   top: -10px;
//   z-index: 1;
//   background-color: white;
//   padding-inline: 6px;
//   display: flex;
//   border: 1px solid #ff4d00;
//   border-radius: 50%;
//   position: absolute;
//   justify-content: center;
//   padding-block: 0.01rem;
//   font-size: 8px;
//   // border-top-left-radius: 4px;
//   // border-top-right-radius: 4px;
// }

// .cardAmountContainer {
//   display: flex;
//   justify-content: center;
//   // padding: 0.2rem;
// }
