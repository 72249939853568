@use 'styles/variables' as variables;

.logoContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 0;

  .usernameContainer {
    text-wrap: nowrap;
  }

  .logo {
    height: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  .name {
    padding-right: 0.7rem;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    color: variables.$bright-grey;
  }
}

.navIcon {
  position: fixed;
  top: 0.3rem;
  left: 0.75rem;
  z-index: 101;
}

.dashboardNameContainer {
  display: flex;
  justify-content: space-between;

  .icon {
    display: inline-flex;
    width: 20px;
  }

  .name {
    margin-left: 1.5rem;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: variables.$bright-grey;
  }
}

.tabContainer {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  .tab {
    padding: 0.5rem 0.8rem;
    margin: 0.1rem 0.3rem;
    border-radius: 0.4rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .icon {
      padding-top: 0.5rem 0 0 2rem;
      color: #525151;
      display: flex;

      img {
        height: 1.2rem;
        filter: invert(0%) sepia(0%) saturate(19%) hue-rotate(355deg) brightness(101%)
          contrast(105%);
      }
    }

    .label {
      padding-left: 0.95rem;
      font-weight: 500;
      font-size: 0.9rem;
      margin-left: 0.4rem;
      white-space: nowrap;
    }

    &:hover {
      cursor: pointer;
      background-color: variables.$light-grey;
    }

    &.active {
      background-color: variables.$navbar-bg;

      .icon {
        color: black;
        img {
          filter: invert(26%) sepia(70%) saturate(2045%) hue-rotate(185deg) brightness(96%)
            contrast(101%);
        }
      }

      .label {
        color: black;
      }
    }
  }
}
