.wonAndLossContainer{
    width: 100%;
    display: flex;
    
    @media only screen and (min-width: 769px) {
        width: 80%;
    }
    
    @media only screen and (min-width: 1025px) {
        width: 50%;
    }

    @media only screen and (min-width: 1441px) {
        width: 40%;
    }
}


