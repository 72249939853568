@use 'styles/variables' as variables;

.wonAndLossContainer {
  width: 100%;
  display: flex;

  @media only screen and (min-width: 769px) {
    width: 80%;
  }

  @media only screen and (min-width: 1025px) {
    width: 50%;
  }

  @media only screen and (min-width: 1441px) {
    width: 40%;
  }
}

.createdCountCard {
  display: flex;
  flex-direction: column;
  align-items: start;
  column-gap: 0.5rem;
  border-radius: 8px;
  background-color: white;
  padding: 0.3rem 0.5rem;
  box-shadow: 1px 1px 5px rgba(255, 77, 0, 0.3);
  cursor: pointer;
  color: variables.$secondary-color;
  transition: all 0.2s ease-in;

  &:hover {
    scale: 1.015;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.1px;
    color: variables.$secondary-color;
  }
}
