@use 'styles/variables' as variables;

.item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.6rem;
  padding-block: 0.7rem;
  padding-inline: 0.3rem;
  border-bottom: 1px solid black;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: variables.$secondary-color;
    color: white;
  }
}
