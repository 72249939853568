.kpiCard {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(255, 122, 64, 0.5);
  border-radius: 8px;
  padding: 0.2rem 0.1rem 0.2rem 0.1rem;

  height: 45px;
  cursor: pointer;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
  transition: all 0.2s ease-in;
  min-width: 80px;
  margin-inline: auto;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 9px;
  }
}

.kpiCardSelected {
  border-left: solid 8px rgba(255, 122, 64);
  box-shadow: 0px 0px 10px rgba(255, 122, 64, 0.5);
  scale: 1.01;
}
