@use 'styles/variables' as variables;

.modalContainer {
  margin: 1rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .timelineAndLimitContainer {
    display: flex;
    justify-content: space-between;
  }
}

.modalFooterContainer {
  position: absolute;
  bottom: 1rem;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
