@use 'styles/variables' as variables;

.uploadContainer {
  border: 1px solid variables.$lighter-border-color;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  height: 1.8rem;

  .content {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
    width: 100%;

    .uploadIcon {
      font-size: 1.5rem;
      color: variables.$slate-grey;
    }

    .uploadButton {
      visibility: hidden;
      display: inline-flex;
      width: 1rem;
      height: 0.5rem;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: rgba(variables.$black, 0.87);

    .content {
      .fileLabel {
        color: rgba(variables.$black, 0.87);
      }
    }
  }
}

.helperText {
  color: variables.$error-color;
  font-size: 0.75rem;
  margin: 3px 14px 0;
}

.fileLabel {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: variables.$slate-grey;
  padding: 0.1rem 0.4rem;
  border-radius: 4px;

  &.fileAdded {
    color: rgba(variables.$black, 0.87);
    background-color: rgba(variables.$success-color, 0.4);
  }
}
