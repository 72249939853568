@use '../../styles/variables' as variables;

.container {
  position: absolute;
  left: 1rem;
}

.drawerList {
  min-width: 15rem;
  padding-top: 1rem;
  padding-left: 0.5rem;

  .logoContainer {
    padding-bottom: 1rem;

    .logo {
      padding-left: 0.5rem;
      height: 1rem;
    }

    .name {
      padding-right: 0.7rem;
      font-size: 0.6rem;
      font-weight: 700;
      padding-left: 0.3rem;
      text-transform: uppercase;
      color: variables.$bright-grey;
    }
  }

  .menus {
    border-bottom: 1px solid rgba(variables.$secondary-font-color, 0.1);
    padding: 0.4rem 0;
    display: flex;
    align-items: center;
  }

  span {
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
    color: variables.$primary-font-color;
   
  }
}

@media only screen and (min-width: 600px) {
  .container {
    display: none;
  }
}

.active{
        background-color: variables.$navbar-bg;
}
