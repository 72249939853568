@use 'styles/variables' as variables;

.topNav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;

  .left {
    display: flex;
    align-items: center;

    .goBackButton {
      margin: 0.5rem 0 !important;
      width: 1.5rem;
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      color: variables.$white;
      cursor: pointer;
      box-shadow: 1px 1px 1px 1px variables.$grey-chateau;
      padding: 0.1rem;

      &:hover {
        box-shadow: 1px 1px 2px 1px variables.$dark-secondary;
      }
    }

    .title {
      font-size: 1em;
      font-weight: 700;
      margin-left: 0.9rem;
    }

    @media only screen and (min-width: 601px) {
      .title {
        font-size: 1.21rem;
      }
    }

    .separator {
      border-left: 2px solid;
      padding-left: 1rem;
    }
  }

  .right {
    align-items: center;

    Button {
      margin: 0 0.5rem;
    }
  }
}
